<template>
  <Page route-path="/index.html">
    <div class="body-container">
      <div class="carousel">
        <el-carousel height="150px">

          <el-carousel-item>
            <div class="carousel">
              <img v-if="screenWidth > 850" src="@/assets/home/carousel-1.png" class="carouselImage" />
              <img v-else src="@/assets/home/carousel-mobile-2.svg" class="carouselImage" />
              <div class="carousel1-content">
                <p class="carousel-title">星河低代码</p>
                <p class="carousel-text">面向专业团队和开发者的企业级低代码平台，成熟、开放、低成本，<br/>提供先进、敏捷的数字化、智能化生产力。</p>
                <div class="carousel1-button">
                  <a href="/lowcode.html"><button class="learnMore">了解更多</button></a>
                  &nbsp;&nbsp;
                  <a href="https://lowcode.linkgie.com#/reg" target="_blank"><button class="learnMore">免费试用</button></a>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel">
              <img v-if="screenWidth > 850" src="@/assets/home/carousel-2.png" class="carouselImage" />
              <img v-else src="@/assets/home/carousel-mobile-3.svg" class="carouselImage" />
              <div class="carousel1-content">
                <p class="carousel-title">千尺链·企业级区块链</p>
                <p class="carousel-text">高性能、强安全、低代码的企业级区块链<br/>单链每秒万级交易速度、亿级海量数据存储、国密标准算法、拜占庭容错共识、多方共治授权，支持企业轻松建立商业化应用场景</p>
                <div class="carousel1-button">
                  <a href="/blockchain.html"><button class="learnMore">了解更多</button></a>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel">
              <img v-if="screenWidth > 850" src="@/assets/home/carousel-3.png" class="carouselImage" />
              <img v-else src="@/assets/home/carousel-mobile-1.png" class="carouselImage" />
              <div class="carousel1-content">
                <p class="carousel-title">四库电子档案系统</p>
                <p class="carousel-text">让企业的电子档案管理变得安全、可控、合规！</p>
                <div class="carousel1-button">
                  <a href="/archives.html"><button class="learnMore">了解更多</button></a>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel">
              <img v-if="screenWidth > 850" src="@/assets/home/carousel-4.png" class="carouselImage" />
              <img v-else src="@/assets/home/carousel-mobile-4.svg" class="carouselImage" />
              <div class="carousel1-content">
                <p class="carousel-title">软件定制</p>
                <p class="carousel-text">
                  提供涵盖咨询、规划、设计、开发与运营的整体数字化方案 <br/>
                  量体裁衣，建立匹配业务/战略目标的IT系统，助推企业数字化发展</p>
                <div class="carousel1-button">
                  <a href="/outsourcing.html"><button class="learnMore">了解更多</button></a>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
  
      <div class="lowcode">
        <div class="title">星河低代码</div>
        <div class="synopsis">开放、可扩展、支持本地原生语言开发的企业级低代码平台</div>
        <div class="lowcode-box">
          <!-- 1 -->
          <div class="lowcode-box-item">
            <div class="lowcode-box-item-icon">
              <img src="@/assets/home/lowcode-1.png" />
            </div>
            <div class="lowcode-box-item-title">
              <span>开箱即用的企业级功能组件</span>
            </div>
            <ul class="lowcode-box-item-text">
              <li>
                丰富的应用组件 + 灵活可扩展的元数据模型
              </li>
              <li>
                可视化定义领域数据模型和交互视图
              </li>
              <li>
                配置式启用“单/多租户/分布式”机构账户模型、“多角色/权限”授权机制，零代码搭建企业级安全管理模型
              </li>
              <li>
                内置丰富的配置式、可插拔的企业级平台功能——应用操作日志、SSH控制台、Web跨域控制、安全证书等
              </li>
              <!-- <li>
                <b>开放的企业应用架构</b> 
                <p style="text-indent: 1.5rem;">前后端多端一体的元数据开发框架</p>
                <p style="text-indent: 1.5rem;">多租户多组织账户注册、认证和管理模型</p>
                <p style="text-indent: 1.5rem;">集成组件/菜单/接口权限的组织角色多级授权体系</p>
                <p style="text-indent: 1.5rem;">授权控制覆盖UI、服务URL、后端组件方法</p>
              </li>
              <li>
                <b>Java & Spring 云原生架构</b> 
                <p style="text-indent: 1.5rem;">原生支持 Spring 框架开发应用</p>
                <p style="text-indent: 1.5rem;">基于元数据快速构建服务与组件</p>
                <p style="text-indent: 1.5rem;">原生集成区块链分布式合约服务</p>
                <p style="text-indent: 1.5rem;">云原生的持续集成与交付（CI/CD）</p>
              </li>
              <li>
                <b>云地协同与私有化</b> 
                <p style="text-indent: 1.5rem;">支持在本地开发应用模块与组件</p>
                <p style="text-indent: 1.5rem;">云地协同的低代码应用开发模式</p>
                <p style="text-indent: 1.5rem;">支持以云端和私有化方式部署管理应用</p>
                <p style="text-indent: 1.5rem;">支持基于应用端点的SSH远程零信任安全访问</p>
              </li> -->
            </ul>
          </div>
          <!-- 2 -->
          <div class="lowcode-box-item">
            <div class="lowcode-box-item-icon">
              <img src="@/assets/home/lowcode-2.png" />
            </div>

            <div class="lowcode-box-item-title">
              <span>低成本、高性能</span>
            </div>
            <ul class="lowcode-box-item-text">
              <li>前后端统一模型驱动开发，1 人轻松做全栈，开发人效提升 100%</li>
              <li>无服务器(Serverless)架构的企业级开发平台，团队远程协同更简单高效</li>
              <li>原生语言编译生成应用，无中间语言解释器，复杂应用运行性能高、资源消耗小</li>
              <li>独立的私有化安装包，无需复杂开发运维设施，快速灵活一键部署应用</li>
              <!-- <li>
                <b>通用的领域模型</b> 
                <p style="text-indent: 1.5rem;">编程语言无关的数据类型系统</p>
                <p style="text-indent: 1.5rem;">面向领域的对象模型元数据</p>
                <p style="text-indent: 1.5rem;">领域模型自动映射开发语言模型</p>
                <p style="text-indent: 1.5rem;">可视化的领域模型编辑器</p>
                <p style="text-indent: 1.5rem;">可视化的UI结构与数据绑定编辑器</p>
              </li>
              <li>
                <b>UI/UE 模式化开发与生成</b> 
                <p style="text-indent: 1.5rem;">基于交互模式定义UI结构元数据</p>
                <p style="text-indent: 1.5rem;">UI结构与UI样式分离</p>
                <p style="text-indent: 1.5rem;">采用原生HTML模板</p>
                <p style="text-indent: 1.5rem;">原生集成 Vue3.0</p>
                <p style="text-indent: 1.5rem;">开放和可扩展的UI组件机制</p>
                <p style="text-indent: 1.5rem;">UI组件绑定后端服务与数据模型</p>
                <p style="text-indent: 1.5rem;">UI组件绑定账号认证授权模型</p>
              </li> -->
              
            </ul>
          </div>
          <!-- 3 -->
          <div class="lowcode-box-item">
            <div class="lowcode-box-item-icon">
              <img src="@/assets/home/lowcode-3.png" />
            </div>
            <div class="lowcode-box-item-title">
              <span>开放体系、充分可扩展</span>
            </div>
            <ul class="lowcode-box-item-text">
              <li>支持原生语言开发（Java, HTML, JS, CSS），程序员零学习门槛</li>
              <li>支持集成开源生态（Spring, Vue, Maven, NPM等），可原生编程，无限制灵活扩展</li>
              <li>支持本地 IDE 开发(Eclipse, IDEA, VsCode)，自主控制源代码，充分保护知识产权</li>
              <li>支持本地持续集成(GitlabCI/CD,Jenkens等)，无缝兼容团队现有的技术开发体系</li>
              <!-- <li>
                <b>组件可独立开发测试与部署</b> 
                <p style="text-indent: 1.5rem;">原生地以组件为基本逻辑单元的架构</p>
                <p style="text-indent: 1.5rem;">低代码或零代码式的组件UI装配</p>
                <p style="text-indent: 1.5rem;">支持前后端一体化集成打包与分发机制</p>
                <p style="text-indent: 1.5rem;">可独立发布和编排的组件后端接口与服务</p>
                
              </li>
              <li>
                <b>面向云的开放式协同分发与共享</b> 
                <p style="text-indent: 1.5rem;">支持云端定义和分发共享组件</p>
                <p style="text-indent: 1.5rem;">支持本地化开发与私有化发布组件</p>
                <p style="text-indent: 1.5rem;">支持开放式/组织共享/私有化的组件模板库</p>
                <p style="text-indent: 1.5rem;">支持组件化的区块链智能合约</p>
              </li>
              <li>
                <b>零代码的模块级应用模板</b> 
                <p style="text-indent: 1.5rem;"></p>
              </li> -->
            </ul>
          </div>

        </div>
        <div class="button-box">
          <a href="/lowcode.html"><button class="learnMore">查看更多</button></a>
        </div>
      </div>
  
      <div class="blockchain">
        <div class="title">千尺区块链底层平台</div>
        <div class="synopsis">企业级、高性能、通用区块链系统</div>
        <div class="blockchain-box">
          <div class="blockchain-box-item" v-for="(item, index) of blockchainList" :key="index">
            <div class="blockchain-box-title">
              {{ item.title }}
            </div>
            <div class="blockchain-box-text">
              {{ item.text }}
            </div>
          </div>
        </div>
        <div class="button-box">
          <!-- <button class="consultation">立即咨询</button> -->
          <a href="/blockchain.html"><button class="learnMore">查看更多</button></a>
        </div>
      </div>

      <div class="archives">
        <div class="title">四库电子档案系统</div>
        <div v-if="screenWidth > 850" class="synopsis">电子档案管理四大特色亮点：100%归档 · 秒级检索 · 文件防篡改 · 可第三方存证</div>
        <div v-else class="synopsis">电子档案管理四大特色亮点<br>100%归档 · 秒级检索 · 文件防篡改 · 可第三方存证</div>
        <div class="archives-box">
          <div class="archives-box-itemlist">
            <div :class="index < archivesList.length - 1 ? 'archives-box-item item-divider' : 'archives-box-item'"
              v-for="(item, index) of archivesList" :key="index">
              <div class="archives-box-img">
                <img :src="item.img" style="width: 30px; height: 30px" />
              </div>
              <div class="archives-box-title">{{ item.title }}</div>
              <div class="archives-box-text">{{ item.text }}</div>
            </div>
          </div>
          <div class="button-box">
            <!-- <button class="consultation">立即咨询</button> -->
            <a href="/archives.html"><button class="learnMore">查看更多</button></a>
          </div>
        </div>
      </div>
  
      <div class="outsourcing">
        <div class="title">IT咨询·软件定制</div>
        <div class="synopsis">提供全方位的深度咨询与专业定制，全流程的IT规划、设计与开发，陪伴企业实现可持续的数字化成长与发展。</div>
        <div class="outsourcing-box">
          <div class="circle">
            <img src="@/assets/home/circle.png" />
            <div class="circle-content">
              <div class="circle-title">
                专业、专注 <br />
                值得信赖
              </div>
              <div class="circle-text">科技创新，客户共赢</div>
            </div>
          </div>
          <div class="outsourcing-box-content">
            <div class="outsourcing-box-content-item" v-for="(item, index) of outsourcingList" :key="index">
              <div class="item-head">
                <div class="item-circle"></div>
                <div class="item-title">{{ item.title }}</div>
              </div>
              <div class="item-text">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="button-box">
          <!-- <button class="consultation">立即咨询</button> -->
          <a href="/outsourcing.html"><button class="learnMore">查看更多</button></a>
          
        </div>
      </div>
    </div>
  </Page>
</template>
<script>
// import { mapGetters } from "vuex";

import { ResponsiveBase } from "../../layout/reponsive-base";

export default {
  name: "HomePage",
  mixins: [ResponsiveBase],

  data() {
    return {
      activeName: "first",
      archivesList: [
        {
          img: require("@/assets/archives/archives_features1.png"),
          title: "一键100%归档",
          text: "四库电子档案系统通过接口自动收集各类电子档案，自动快速分拣、核验、整理，一键实现100%归档",
        },
        {
          img: require("@/assets/archives/archives_features2.png"),
          title: "秒级检索，快速利用",
          text: "四库电子档案系统的档案利用率高，以电子会计档案为例，系统可自动建立账簿、报表、合同等信息的关联，实现“业务-财务-档案”的联查调阅与分析，全面提升档案利用水平",
        },
        {
          img: require("@/assets/archives/archives_features3.png"),
          title: "安全可信、防篡改",
          text: "利用自主开发的区块链技术，对归档文件自动加盖电子签名，助力电子档案系统具备防篡改的能力",
        },
        {
          img: require("@/assets/archives/archives_features4.png"),
          title: "存储高效、低成本",
          text: "数据高效压缩分库存储、数据分布存储、异地灾备，保证数据永久有效存储",
        },
      ],
      lowcodeList: [
       // 移到页面内容中；
      ],
      blockchainList: [
        {
          title: "简洁至上",
          text: "提供面向 Java 开发者友好的开发方式，渐进式学习，约定优于配置，轻量部署，快速上手。",
        },
        {
          title: "可靠灵活",
          text: "原生支持国家密码标准算法，采用拜占庭容错（BFT）共识协议，提供通用的账本数据结构和开发工具，保证区块链服务安全可靠、适用和可扩展。",
        },
        {
          title: "高性能",
          text: "无需专用硬件，采用通用服务器即可支撑企业级的高吞吐量事务处理和海量的数据上链存储。",
        },
      ],
      outsourcingList: [
          {
              title: '专业',
              text: '平均十年以上资深产品研发团队，有丰富的大型企业系统、互联网平台建设研发经验。',
          },
          {
              title: '敏捷',
              text: '有成熟的项目建设经验，先进的敏捷开发流程，前沿的架构思想和技术组件，为客户提供随需应变的能力。',
          },
          {
              title: '高效',
              text: '基于先进的星河低代码平台，研发效率提升 1 倍，研发成本降低50%，为客户充分解放数字化生产力。',
          },
          {
              title: '全面',
              text: '基于丰富的项目经验和成熟的产品技术沉淀，不仅有先进的研发能力，还有完整的IT规划设计能力，为客户提供全方位深度服务。',
          },
      ],
    };
  },
  created() { },
  inject: ["pageIndicator"],

  mounted() { },

  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },

    routeTo(route) {
      if (route.endsWith(".html")) {
        document.location.href = route;
      }else{
        console.error("上下文不允许使用路由机制！");
      }
    },
  },
};
</script>
<style lang="less" scoped>

.contact-us {
  position:fixed;

  top: calc(50vh - 45px);
  right: 20px;
  width: 90px;
  height: 90px;

  box-sizing: border-box;
  border-style: solid;
  border-color: #f0f2f9;
  border-width: 2px;

  border-radius: 45px;

  @media screen and (max-width: 850px) {
      top: calc(50vh - 30px);
      right: 10px;
      width: 60px;
      height: 60px;
  }
}

.contact-us:hover {
  // 大小发生变化之后，为了维持图标的中心位置不动，因此要调整 top 、right 坐标减少增加高度和宽度的一半；
  top: calc(50vh - 49px);
  right: calc(20px - 4px);
  width: 98px;
  height: 98px;

  border-width: 4px;
  border-color: #d3dce6;

  border-radius: 49px;

  cursor:pointer;

  transition-duration: .4s;

  @media screen and (max-width: 850px) {
      top: calc(50vh - 32px);
      right: calc(10px - 4px);
      width: 64px;
      height: 64px;
  }
}


.contact-us .contact-us-hint {
  position:absolute;

  top: 96px;
  width: 100%;
  height: 1.8rem;

  border-radius: 1.2rem;
  border-width: 1px;
  border-color: #ffffff00;
  background-color: #ffffff00;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 850px) {
      top: 56px;
      width: 100%;
      height: 1.8rem;
  }
}

.contact-us .contact-us-hint span{
  @media screen and (max-width: 850px) {
    font-size: x-small;
  }
}

.contact-us:hover .contact-us-hint {
  // 由于父 div 高度减少 2 ，为维持相对于浏览器底部的高度不变，因此高度要增加 2 ；
  top:98px;
  border-style: solid;
  border-color: #b4b6b8;
  
  background-color: #58a0ad37;

  transition-duration: .4s;

  @media screen and (max-width: 850px) {
      top: 58px;
      width: 100%;
      height: 1.6rem;
  }
}


.contact-us .contact-code {
  position:absolute;

  top: 150px;
  right: -15px;

  width: 120px;
  height: 0;

  border-radius: 10px;

  background-color: #58a0ad37;
  padding: 6px;

  visibility:hidden;

  box-sizing: border-box;

  @media screen and (max-width: 850px) {
    top: 90px;
    right: -8px;

    width: 80px;
    height: 80px;

    visibility:visible;
  }
}

.contact-us:hover .contact-code {
  // 由于父 div 高度减少 2 ，为维持相对于浏览器底部的高度不变，因此高度要增加 2 ；
  top:150px;
  right: -13px;

  visibility:visible;

  width: 120px;
  height: 120px;
  border-radius: 10px;

  transition-duration: .4s;

  @media screen and (max-width: 850px) {
    top: 90px;
    right: -8px;

    width: 80px;
    height: 80px;

    visibility:visible;
  }
}

span {
  font-size: 16px;
  color: #040420;
  font-weight: 400;
  line-height: 30px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
}

.body-container {
  padding-top: 65px;
  width: 100%;
}

.el-carousel {
  width: 100%;
}

:deep(.el-carousel .el-carousel__container) {
  height: 540px !important;
  width: 100%;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.carousel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .carousel1-content {
    max-width: 1280px;
    width: 100%;
    position: absolute;
    padding: 0 20px;
  }

  .carouselImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.carousel-title {
  font-size: 64px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #040420;
}

.carousel-text {
  font-size: 21px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #727272;
  margin-top: 36px;
  margin-bottom: 48px;
  line-height: 38px;
  max-width: 720px;
}

@media screen and (max-width: 850px) {
  .carousel1-content {
    width: 100%;
    text-align: center;
    top: 100px;
  }

  .carousel-title {
    font-size: 36px;

  }

  .carousel-text {
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 34px;
    line-height: 24px;
  }
}

.title {
  font-size: 36px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #040420;
  line-height: 36px;
}

.synopsis {
  font-size: 20px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #727272;
  line-height: 30px;
  margin-top: 30px;
  margin-bottom: 60px;
}

@media screen and (max-width: 850px) {
  .title {
    font-size: 24px;
  }

  .synopsis {
    font-size: 14px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
  }
}

.archives {
  padding: 100px 30px 80px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 850px) {
    padding: 40px 14px 0px 14px;
  }

  .archives-box {
    width: 100%;
    max-width: 1280px;

    background: hsl(227, 43%, 96%);

    @media screen and (max-width: 850px) {
      background: #ffffff;
    }

    .archives-box-itemlist {
      padding-top: 60px;
      margin-bottom: 60px;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 60px;

      @media screen and (max-width: 850px) {
        padding-top: 0px;
        margin-bottom: 0px;
        row-gap: 20px;
      }

      .archives-box-item {
        padding: 0px 38px;
        width: 300px;
        text-align: center;

        @media screen and (max-width: 1280px) {
          width: 45%;
          max-width: 580px;
          column-gap: 30px;
        }

        @media screen and (max-width: 850px) {
          width: 100%;
          max-width: 800px;
          padding: 0px 16px;
          column-gap: 10px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          background: hsl(227, 43%, 96%);
          padding-bottom: 20px;
          border-radius: 10px;
        }

        .archives-box-img {
          @media screen and (max-width: 850px) {
            padding-top: 15px;
          }
        }

        .archives-box-title {
          font-size: 24px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #040420;
          line-height: 24px;
          margin-top: 48px;
          margin-bottom: 32px;

          @media screen and (max-width: 1280px) {
            margin-top: 24px;
          }

          @media screen and (max-width: 850px) {
            font-size: 18px;
            margin-top: 24px;
            margin-bottom: 15px;
          }
        }

        .archives-box-text {
          width: 100%;
          font-size: 18px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #727272;
          line-height: 32px;
          word-break: break-word;
          text-align: left;

          @media screen and (max-width: 850px) {
            padding-left: 40px;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      .item-divider {
        border-right: 1px solid #cbceda;

        @media screen and(max-width:1280px) {
          border-right: none;
        }
      }
    }
  }
}

.lowcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: #f0f2f9;

  padding: 100px 30px 40px 30px;

  @media screen and (max-width: 850px) {
    padding: 40px 14px 0px 14px;
  }
  .lowcode-box {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
    column-gap: 1.5rem;
    row-gap: 1rem;
    box-sizing: border-box;

    @media screen and(max-width:1280px) {
      justify-content: center;
      padding: 0px;
      margin: 0px;
    }

    .lowcode-box-item {
      width: 400px;
      // width: 100%;
      // height: 480px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      text-align: center;
      padding: 36px 8px;

      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and(max-width:1280px) {
        // width: 30%;
      }

      @media screen and(max-width:850px) {
        width: 100%;
        max-width: 1280px;
        height: auto;
        padding: 14px;

        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .lowcode-box-item-icon {
        width: fit-content;
        height: 64px;
        padding: 0;

        img {
          width: auto;
          height: 100%;
          object-fit: scale-down;
        }

        @media screen and(max-width:1280px) {
          // width: auto;
          height: 60px;

          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }

        @media screen and(max-width:520px) {
          // width: fit-content;
          height: 30px;
        }
      }

      .lowcode-box-item-title {
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #040420;
        line-height: 24px;
        margin: 20px 0px;
        width: auto;

        span {
          font-size: 24px;
          font-weight: 500;

          @media screen and(max-width:850px) {
            font-size: 18px;
          }
        }

        @media screen and(max-width:1280px) {
          // width: 50%;
          min-width: 240px;
          text-align: center;
          margin: 0;
        }

        @media screen and(max-width:850px) {
          text-align: left;
        }

        @media screen and(max-width:520px) {
          // width: 100%;
          min-width: unset;
          text-align: center;
          margin: 0;
        }
      }

      .lowcode-box-item-text {
        width: 22rem;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #727272;
        line-height: 32px;

        word-break: break-word;
        text-align: left;

        padding-inline-start: 1.5rem;

        @media screen and(max-width:1280px) {
          // width: 100%;
          height: auto;
          padding-top: 24px;
        }

        @media screen and(max-width:850px) {
          width: 100%;
          font-size: 14px;
          line-height: 24px;
          padding-top: 14px;
        }

        p{
          font-size: 16px;
        }

        li{
          list-style-type:circle;
          // 文本换行缩进 1.4 rem；
          // text-indent: -1.4rem;
          word-break: break-word;
        }
      }
    }

    .lowcode-box-item:hover {
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);

      @media screen and(max-width:850px) {
        // box-shadow: none;
      }
    }
  }
}

.blockchain {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f2f9;

  padding: 100px 30px 60px 30px;

  @media screen and (max-width: 850px) {
    padding: 40px 14px 0px 14px;
  }

  .blockchain-box {
    max-width: 1280px;
    width: 100%;
    margin-bottom: 60px;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 28px;

    @media screen and(max-width:1280px) {
      column-gap: 28px;
      justify-content: center;
    }

    @media screen and(max-width:850px) {
      margin: 0px;
    }

    @media screen and(max-width:500px) {
      row-gap: 14px;
    }

    .blockchain-box-item {
      width: 33%;
      max-width: 400px;
      height: auto;
      min-height: 300px;

      background: #ffffff;
      box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      padding: 48px;

      @media screen and(max-width:850px) {
        width: 100%;
        max-width: 100%;
        min-height: 150px;
        padding: 30px;
      }

      @media screen and(max-width:500px) {
        width: 100%;
        max-width: 100%;
        min-height: auto;
        padding: 14px;
      }

      .blockchain-box-title {
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #040420;
        line-height: 24px;
        margin-bottom: 24px;
        border-left: 6px solid #2e64f3;
        padding-left: 12px;

        @media screen and(max-width:850px) {
          font-size: 18px;
          line-height: 18px;
        }
      }

      .blockchain-box-text {
        // width: 312px;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #727272;
        line-height: 32px;

        @media screen and(max-width:850px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    .blockchain-box-item:hover {
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
    }
  }
}

.outsourcing {
  //   height: 1111px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #f0f2f9;
  padding: 100px 30px 80px 30px;

  @media screen and (max-width: 850px) {
    padding: 40px 14px 0px 14px;
  }

  .outsourcing-box {
    width: 100%;
    max-width: 1280px;
    min-height: 643px;
    margin-bottom: 88px;
    position: relative;

    display: flex;

    @media screen and (max-width: 850px) {
      flex-direction: column;
      row-gap: 24px;
      align-items: center;

      max-width: 500px;
      margin: 0px;
    }

    @media screen and (max-width: 500px) {
      row-gap: 12px;
      min-height: unset;
    }

    .circle {
      //   background: url(../assets/home/circle.png) no-repeat center center;
      position: relative;
      background-size: 100% 100%;
      width: 640px;
      height: fit-content;
      padding: 0px;

      @media screen and (max-width: 850px) {
        width: 50%;
        min-width: 300px;
      }

      @media screen and (max-width: 500px) {
        width: 50%;
        min-width: 240px;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: scale-down;
        z-index: 500;
      }

      .circle-content {
        width: 180px;
        height: 150px;
        position: absolute;
        top: calc(50% - 80px);
        left: calc(50% - 80px);

        box-sizing: border-box;
        z-index: 1000;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: space-between;

        @media screen and (max-width: 850px) {
          width: 160px;
          height: 75px;
          row-gap: 12px;

          top: calc(50% - 50px);
          left: calc(50% - 45px);
        }
      }

      .circle-title {
        // width: 160px;
        font-size: 32px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #fdfdff;
        line-height: 52px;

        @media screen and (max-width: 850px) {
          font-size: 16px;
          line-height: 28px;
        }
      }

      .circle-text {
        // width: 180px;
        font-size: 20px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;

        @media screen and (max-width: 850px) {
          font-size: 12px;
          line-height: 24px;
        }
      }
    }

    .outsourcing-box-content {
      position: absolute;
      left: 500px;
      height: 645px;
      padding-top: 51px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 850px) {
        position: unset;
        width: 100%;
        height: auto;

        left: auto;
        right: 0px;
        top: 0px;
        padding: 0px;

        row-gap: 14px;
      }

      .outsourcing-box-content-item {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        align-items: flex-start;

        .item-head {
          display: flex;
          gap: 20px;

          @media screen and (max-width: 850px) {
            gap: 12px;
          }
        }

        .item-circle {
          width: 24px;
          height: 24px;
          background: #bbccf8;
          border-radius: 50%;
        }

        .item-title {
          font-size: 24px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #040420;
          line-height: 24px;
          margin-bottom: 20px;
        }

        .item-text {
          font-size: 18px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          word-break: break-word;
          color: #727272;
          line-height: 32px;
          padding-left: 44px;
        }

        @media screen and (max-width: 850px) {
          min-height: 60px;

          padding-left: 0px !important;
          .item-circle {
            width: 18px;
            height: 18px;
           
          }
          .item-title {
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 8px;
          }

          .item-text {
            font-size: 14px;
            line-height: 22px;
            padding-left: 38px;
          }
        }


        @media screen and (max-width: 500px) {
          min-height: unset;
        }
      }

      .outsourcing-box-content-item:nth-child(2) {
        padding-left: 118px;
      }

      .outsourcing-box-content-item:nth-child(3) {
        padding-left: 118px;
      }
    }
  }
}

.button-box {
  display: flex;
  justify-content: center;

  padding: 30px 0;
  
  @media screen and (max-width: 850px) {
    padding: 20px 0;
  }
  @media screen and (max-width: 500px) {
    padding: 14px 0;
  }
}

.consultation {
  width: 160px;
  height: 54px;
  background: #2e64f3;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 20px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  border: none;
}

.learnMore {
  width: 160px;
  height: 54px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #e5e6e8;
  font-size: 20px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #040420;
}

.learnMore:hover {
  border: 1px solid #2e64f3;
  color: #2e64f3;
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .learnMore {
    width: 98px;
    height: 32px;
    font-size: 12px;
  }
}
</style>
